import "@splidejs/splide/dist/css/splide.min.css";

import Splide from "@splidejs/splide";
import React, { useEffect, useState } from "react";
import { Button, Container, Icon, Transition } from "semantic-ui-react";

import th1 from "../images/thermometer/thermometer_0.png";
import th10 from "../images/thermometer/thermometer_9.png";
import th11 from "../images/thermometer/thermometer_10.png";
import th2 from "../images/thermometer/thermometer_1.png";
import th3 from "../images/thermometer/thermometer_2.png";
import th4 from "../images/thermometer/thermometer_3.png";
import th5 from "../images/thermometer/thermometer_4.png";
import th6 from "../images/thermometer/thermometer_5.png";
import th7 from "../images/thermometer/thermometer_6.png";
import th8 from "../images/thermometer/thermometer_7.png";
import th9 from "../images/thermometer/thermometer_8.png";

const thermometers = [th1, th2, th3, th4, th5, th6, th7, th8, th9, th10, th11];

const data = [
	[
		'Launch sale & get verified on <a href="https://www.jpg.store/">Jpg.store</a> + daily giveaways',
	],
	// ["Rarity chart + Sales Bot"],
	// ["Comic Book #1 black & white artwork"],
	// ["Comic Book #1 artwork colored & lettering"],
	// ["Comic Book #1 digital/NFT released (featuring Space Babez & Ooz1es)"],
	// ["Comic Book #2 black & white artwork"],
	// ["Comic Book #2 artwork colored & lettering"],
	// ["Comic Book #2 digital/NFT released (featuring Space Babez & Ooz1es)"],
	// [
	// 	"Comic Book 3D Avatar created for movie, music, NFT reviews & marketing/promotion + interviews",
	// ],
	// [
	// 	"Comic Book prop made for real life skits on Instagram/Facebook/Youtube/TikTok/Twitter etc",
	// ],
	[
		"Space Babez 2 100% on-chain project featuring all new art work and NFT interactivity",
	],
];

const valData = [96, 92, 84, 75, 67, 58, 50, 41, 33, 16];

let splide = null;

export default function Roadmap() {
	const [range, setRange] = useState(0);
	const [img, setImg] = useState(0);
	const [open, setOpen] = useState(false);
	const [visible, setVisible] = useState(false);
	function toggleVisibility() {
		setOpen(!open);
		setVisible(!visible);
	}
	function initSplide() {
		splide = new Splide(".r-splide", {
			start: 0,
			arrows: false,
			direction: "ttb",
			height: 600,
			heightRatio: 0.33,
			pagination: false,
			perPage: 3,
			focus: 0,
			keyboard: false,
			trimSpace: false,
			easing: "cubic-bezier(.69,.04,.32,.97)",
		});
		splide.mount();
		splide.on("moved", function () {
			setImg(splide.index > 0 ? 10 : 0);
			setTimeout(() => {
				setRange(valData[splide.index]);
			}, 300);
		});
	}
	useEffect(() => {
		initSplide();
	}, []);
	function handleChange(e) {
		const v = e.target.value;
		let val;
		if (v >= 96) {
			val = 10;
		} else if (v >= 92) {
			val = 9;
		} else if (v >= 84) {
			val = 8;
		} else if (v >= 75) {
			val = 7;
		} else if (v >= 67) {
			val = 6;
		} else if (v >= 58) {
			val = 5;
		} else if (v >= 50) {
			val = 4;
		} else if (v >= 41) {
			val = 3;
		} else if (v >= 33) {
			val = 2;
		} else if (v >= 16) {
			val = 10;
		} else {
			val = 0;
		}
		if (splide) {
			splide.go(val);
		}
	}
	function goTo(idx) {
		if (splide) {
			setRange(valData[idx]);
			splide.go(idx);
		} else {
			initSplide();
		}
	}
	return (
		<div>
			<Container text fluid>
				<Button
					fluid
					onClick={toggleVisibility}
					className={"faq-button" + (visible ? " opened" : "")}
					style={{ marginBottom: 0 }}
					basic
				>
					<span>Roadmap</span>
					<div className="trw">
						<Transition
							visible={visible}
							animation="vertical flip"
							duration={200}
						>
							<Icon
								color={visible ? "green" : "black"}
								name="angle up"
								size="large"
							/>
						</Transition>
					</div>
					<div className="trw">
						<Transition
							visible={!visible}
							animation="vertical flip"
							duration={200}
						>
							<Icon name="angle down" size="large" />
						</Transition>
					</div>
				</Button>
			</Container>
			<div
				className="roadmap-container"
				style={{ display: open ? "flex" : "none" }}
			>
				<div className="thermometer-container">
					<img
						src={thermometers[img]}
						alt="thermometer"
						className="thermometer"
					/>
					<input
						type="range"
						min={0}
						max={100}
						step={1}
						value={range}
						onChange={handleChange}
					/>
				</div>
				<div className="r-splide text-container">
					<div className="splide__track">
						<div className="splide__list">
							{data.map((d, i) => (
								<div
									key={i}
									className="splide__slide"
									aria-hidden="true"
									onClick={() => goTo(i)}
									onKeyDown={() => goTo(i)}
								>
									{d.map((item, idx) => (
										<div key={idx}>
											<div className="dot" style={{ width: 8, height: 8 }} />{" "}
											<span
												style={{ flex: 1 }}
												dangerouslySetInnerHTML={{ __html: item }}
											/>
										</div>
									))}
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
