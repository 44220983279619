import React from "react";
import { Button, Grid, Icon, Transition } from "semantic-ui-react";

import diconcilio from "../images/avatars/diconcilio.png";
import dii_casses from "../images/avatars/dii_casses.png";
import equinox from "../images/avatars/equinox.png";
import shakil from "../images/avatars/shakil.png";

const avatars = [
	{
		id: 10,
		name: "Dii Casses",
		role: "Creator",
		image: dii_casses,
		discord: "diicasses",
		link: "https://anti.biz",
	},
	{ id: 1, name: "Diconcilio", role: "Artist", image: diconcilio },
	{
		id: 2,
		name: "Equinox",
		role: "Blockchain Developer",
		image: equinox,
		discord: "equinox1175",
	},
	{
		id: 3,
		name: "Shakil",
		role: "Web Developer",
		image: shakil,
		discord: "sxakil",
		link: "https://shakil.info",
	},
];

export default function TeamMembers() {
	const [open, setOpen] = React.useState(false);
	const [visible, setVisible] = React.useState(false);
	function toggleVisibility() {
		setOpen(!open);
		setVisible(!visible);
	}
	return (
		<div style={{ marginTop: 20 }}>
			<Button
				fluid
				onClick={toggleVisibility}
				className={"faq-button" + (visible ? " opened" : "")}
				basic
			>
				<span>Team</span>
				<div className="trw">
					<Transition
						visible={visible}
						animation="vertical flip"
						duration={200}
					>
						<Icon
							color={visible ? "green" : "black"}
							name="angle up"
							size="large"
						/>
					</Transition>
				</div>
				<div className="trw">
					<Transition
						visible={!visible}
						animation="vertical flip"
						duration={200}
					>
						<Icon name="angle down" size="large" />
					</Transition>
				</div>
			</Button>
			<Transition animation="slide down" visible={visible}>
				<Grid columns={2} centered container stackable stretched>
					{avatars.map((avatar) => (
						<Grid.Column key={avatar.id} style={{ alignItems: "center" }}>
							<a
								href={avatar.link || "#"}
								target={avatar.link ? "_blank" : ""}
								rel="noreferrer"
								className="team-member"
							>
								<img src={avatar.image} alt={avatar.name} />
								<div className="team-text">
									<p className="name">{avatar.name}</p>
									<p className="position">{avatar.role}</p>
									{!!avatar.discord && (
										<p className="discord">
											<Icon name="discord" /> {avatar.discord}
										</p>
									)}
								</div>
							</a>
						</Grid.Column>
					))}
				</Grid>
			</Transition>
		</div>
	);
}
